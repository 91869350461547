var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"green","indeterminate":"","z-index":"2"}})],1),_c('v-container',{staticClass:"px-6 py-6",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('v-card',{staticClass:"card-shadow border-radius-xl"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('h5',{staticClass:"font-weight-bold text-h5 text-typo mb-0 mx-5 mt-2"},[_vm._v(" Independent Weigh Up Tasting ")])])]),_c('div',{staticClass:"mx-5 mb-5"},[_c('v-form',{ref:"frmAuctionFilter"},[_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","xs":"12","lg":"3"}},[_c('label',{staticClass:"text-md text-typo font-weight-bolder ms-1"},[_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("From (Start date)")]),_c('v-menu',{ref:"fromDate_menu",attrs:{"close-on-content-click":false,"return-value":_vm.fromDate_menu,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.fromDate_menu=$event},"update:return-value":function($event){_vm.fromDate_menu=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"\n                            input-style\n                            font-size-input\n                            text-light-input\n                            placeholder-light\n                            input-icon\n                            mt-5\n                          ",attrs:{"hide-details":"auto","dense":"","flat":"","filled":"","solo":"","height":"46","placeholder":"Start Date","persistent-hint":""},on:{"change":function($event){return _vm.getDispatchList()}},model:{value:(_vm.editedItem.fromDate),callback:function ($$v) {_vm.$set(_vm.editedItem, "fromDate", $$v)},expression:"editedItem.fromDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fromDate_menu),callback:function ($$v) {_vm.fromDate_menu=$$v},expression:"fromDate_menu"}},[_c('v-date-picker',{attrs:{"color":"green lighten-1","header-color":"primary","max":_vm.editedItem.toDate,"no-title":"","scrollable":""},model:{value:(_vm.editedItem.fromDate),callback:function ($$v) {_vm.$set(_vm.editedItem, "fromDate", $$v)},expression:"editedItem.fromDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.fromDate_menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.fromDate_menu.save(_vm.editedItem.fromDate),
                              _vm.getDispatchList()}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","xs":"12","lg":"3"}},[_c('label',{staticClass:"text-md text-typo font-weight-bolder ms-1"},[_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("To (End date)")]),_c('v-menu',{ref:"toDate_menu",attrs:{"close-on-content-click":false,"return-value":_vm.toDate_menu,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.toDate_menu=$event},"update:return-value":function($event){_vm.toDate_menu=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
                              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"\n                            input-style\n                            font-size-input\n                            text-light-input\n                            placeholder-light\n                            input-icon\n                            mt-5\n                          ",attrs:{"hide-details":"auto","dense":"","flat":"","filled":"","solo":"","height":"46","placeholder":"To Date","persistent-hint":""},model:{value:(_vm.editedItem.toDate),callback:function ($$v) {_vm.$set(_vm.editedItem, "toDate", $$v)},expression:"editedItem.toDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.toDate_menu),callback:function ($$v) {_vm.toDate_menu=$$v},expression:"toDate_menu"}},[_c('v-date-picker',{attrs:{"color":"green lighten-1","header-color":"primary","min":_vm.editedItem.fromDate,"no-title":"","scrollable":""},model:{value:(_vm.editedItem.toDate),callback:function ($$v) {_vm.$set(_vm.editedItem, "toDate", $$v)},expression:"editedItem.toDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.toDate_menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.toDate_menu.save(_vm.editedItem.toDate),
                              _vm.getDispatchList()}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","xs":"12","lg":"3"}},[_c('label',{staticClass:"text-md text-typo font-weight-bolder ms-1"},[_vm._v(" Dispatch code")]),_c('v-select',{staticClass:"\n                        input-style\n                        font-size-input\n                        text-light-input\n                        placeholder-light\n                        border-radius-md\n                        select-style\n                        mt-5\n                        mb-0\n                      ",attrs:{"items":_vm.dispatches,"item-text":"dispatch_code","return-object":"","hide-details":"auto","outlined":"","height":"46","single-line":"","rules":[function (v) { return !!v || 'Dispatch code is required'; }],"placeholder":"Select dispatch code"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                              var item = ref.item;
return [_c('v-text',{staticClass:"py-1 px-2 my-0",attrs:{"label":"","color":"bg-default"}},[_c('span',{staticClass:"text-caption ls-0"},[_vm._v(_vm._s(item.dispatch_code))])])]}}]),model:{value:(_vm.dispatch),callback:function ($$v) {_vm.dispatch=$$v},expression:"dispatch"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end mx-5"},[_c('v-btn',{staticClass:"\n                        font-weight-bold\n                        text-capitalize\n                        btn-ls btn-secondary\n                        bg-light\n                        px-6\n                        mr-2\n                      ",attrs:{"elevation":"0","ripple":false,"height":"46"},on:{"click":_vm.clearData}},[_vm._v("Clear")]),_c('v-btn',{staticClass:"\n                        font-weight-bold\n                        text-capitalize\n                        btn-ls btn-primary\n                        bg-success\n                        px-6\n                      ",attrs:{"elevation":"0","ripple":false,"height":"46","dark":""},on:{"click":function($event){return _vm.searchDispatch()}}},[_vm._v("Search")])],1)],1)],1)],1)]),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_dispatch_table),expression:"show_dispatch_table"}],staticClass:"card-shadow border-radius-xl mt-6",attrs:{"id":"scroll_1"}},[_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-form',{ref:"frmTable"},[_c('v-card-text',{staticClass:"card-padding"},[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.auctionHeader,"items":_vm.dispatchItems,"item-key":"tid","height":"600","fixed-header":"","footer-props":{
                      'items-per-page-options': [100, 200, 500, -1],
                    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title'),_c('v-spacer'),_c('v-btn',{staticClass:"\n                            font-weight-bold\n                            text-capitalize\n                            btn-ls btn-primary\n                            bg-success\n                            py-3\n                            px-6\n                            mb-5\n                            mt-5\n                          ",attrs:{"elevation":"0","ripple":false,"height":"46","dark":""},on:{"click":_vm.submitDispatch}},[_vm._v("Submit ")])],1)]},proxy:true},{key:"item.remarks",fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [_c('div',[_c('v-text-field',{ref:("input-" + (index * 9 + 9)),staticClass:"\n                            input-style\n                            font-size-input\n                            text-light-input\n                            placeholder-light\n                            input-icon\n                            mt-2\n                            mb-4\n                            centered-input\n                          ",attrs:{"hide-details":"auto","dense":"","flat":"","filled":"","solo":"","height":"46","persistent-hint":""},on:{"keyup":_vm.changeFocusInput},model:{value:(item.remarks),callback:function ($$v) {_vm.$set(item, "remarks", $$v)},expression:"item.remarks"}})],1)]}},{key:"item.generated_item_name",fn:function(ref){
                    var item = ref.item;
return [_c('v-text-field',{staticClass:"\n                          input-style\n                          font-size-input\n                          text-light-input\n                          placeholder-light\n                          input-icon\n                          mt-2\n                          mb-4\n                          centered-input\n                        ",attrs:{"disabled":"","hide-details":"auto","dense":"","flat":"","filled":"","solo":"","height":"46","persistent-hint":""},model:{value:(item.generated_item_name),callback:function ($$v) {_vm.$set(item, "generated_item_name", $$v)},expression:"item.generated_item_name"}})]}},{key:"item.special_marking",fn:function(ref){
                    var item = ref.item;
return [_c('v-text-field',{staticClass:"\n                          input-style\n                          font-size-input\n                          text-light-input\n                          placeholder-light\n                          input-icon\n                          mt-2\n                          mb-4\n                          centered-input\n                        ",staticStyle:{"width":"100px"},attrs:{"tabindex":"-1","hide-details":"auto","dense":"","flat":"","filled":"","solo":"","height":"46","value":item.special_marking,"persistent-hint":""},on:{"input":function($event){return _vm.getItemName(item)},"keyup":_vm.changeFocusInput},model:{value:(item.special_marking),callback:function ($$v) {_vm.$set(item, "special_marking", $$v)},expression:"item.special_marking"}})]}},{key:"item.leaf_appearance",fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [_c('v-select',{ref:("input-" + (index * 9 + 3)),staticClass:"\n                          input-style\n                          font-size-input\n                          text-light-input\n                          placeholder-light\n                          border-radius-md\n                          select-style\n                          mb-0\n                          mt-5\n                        ",attrs:{"items":_vm.leaf_appearance,"item-text":"value","item-value":"value","outlined":"","single-line":"","height":"46"},on:{"change":function($event){return _vm.getItemName(item)},"keyup":_vm.changeFocusInput},model:{value:(item.leaf_appearance),callback:function ($$v) {_vm.$set(item, "leaf_appearance", _vm._n($$v))},expression:"item.leaf_appearance"}})]}},{key:"item.leaf_size",fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [_c('v-select',{ref:("input-" + (index * 9 + 2)),staticClass:"\n                          input-style\n                          font-size-input\n                          text-light-input\n                          placeholder-light\n                          border-radius-md\n                          select-style\n                          mb-0\n                          mt-5\n                        ",attrs:{"items":_vm.leaf_size,"item-text":"value","item-value":"value","outlined":"","single-line":"","height":"46"},on:{"change":function($event){return _vm.getItemName(item)},"keyup":_vm.changeFocusInput},model:{value:(item.leaf_size),callback:function ($$v) {_vm.$set(item, "leaf_size", _vm._n($$v))},expression:"item.leaf_size"}})]}},{key:"item.color",fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [_c('v-select',{ref:("input-" + (index * 9 + 4)),staticClass:"\n                          input-style\n                          font-size-input\n                          text-light-input\n                          placeholder-light\n                          border-radius-md\n                          select-style\n                          mb-0\n                          mt-5\n                        ",attrs:{"items":_vm.color,"item-text":"value","item-value":"value","outlined":"","single-line":"","height":"46"},on:{"change":function($event){return _vm.getItemName(item)},"keyup":_vm.changeFocusInput},model:{value:(item.color),callback:function ($$v) {_vm.$set(item, "color", _vm._n($$v))},expression:"item.color"}})]}},{key:"item.body",fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [_c('v-select',{ref:("input-" + (index * 9 + 5)),staticClass:"\n                          input-style\n                          font-size-input\n                          text-light-input\n                          placeholder-light\n                          border-radius-md\n                          select-style\n                          mb-0\n                          mt-5\n                        ",attrs:{"items":_vm.body,"item-text":"value","item-value":"value","outlined":"","single-line":"","height":"46"},on:{"change":function($event){return _vm.getItemName(item)},"keyup":_vm.changeFocusInput},model:{value:(item.body),callback:function ($$v) {_vm.$set(item, "body", _vm._n($$v))},expression:"item.body"}})]}},{key:"item.taste",fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [_c('v-select',{ref:("input-" + (index * 9 + 6)),staticClass:"\n                          input-style\n                          font-size-input\n                          text-light-input\n                          placeholder-light\n                          border-radius-md\n                          select-style\n                          mb-0\n                          mt-5\n                        ",attrs:{"items":_vm.taste,"item-text":"value","item-value":"value","outlined":"","single-line":"","height":"46"},on:{"keyup":_vm.changeFocusInput,"change":function($event){return _vm.getItemName(item)}},model:{value:(item.taste),callback:function ($$v) {_vm.$set(item, "taste", _vm._n($$v))},expression:"item.taste"}})]}},{key:"item.comment",fn:function(ref){
                    var item = ref.item;
return [_c('v-text-field',{staticClass:"\n                          input-style\n                          font-size-input\n                          text-light-input\n                          placeholder-light\n                          input-icon\n                          mt-2\n                          mb-4\n                          centered-input\n                        ",attrs:{"hide-details":"auto","dense":"","flat":"","filled":"","solo":"","height":"46","value":item.comment,"persistent-hint":""},model:{value:(item.comment),callback:function ($$v) {_vm.$set(item, "comment", $$v)},expression:"item.comment"}})]}},{key:"item.below_statndard",fn:function(ref){
                    var item = ref.item;
return [_c('v-text-field',{staticClass:"\n                          input-style\n                          font-size-input\n                          text-light-input\n                          placeholder-light\n                          input-icon\n                          mt-2\n                          mb-4\n                          centered-input\n                        ",attrs:{"hide-details":"auto","dense":"","flat":"","filled":"","solo":"","height":"46","value":item.below_statndard,"persistent-hint":""},model:{value:(item.below_statndard),callback:function ($$v) {_vm.$set(item, "below_statndard", $$v)},expression:"item.below_statndard"}})]}},{key:"item.status",fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [(
                          item.item_code != item.generated_item_name &&
                          item.generated_item_name != null
                        )?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.allowedActions.length > 0),expression:"allowedActions.length > 0"}]},[_c('v-select',{ref:("input-" + (index * 9 + 7)),staticClass:"\n                            input-style\n                            font-size-input\n                            text-light-input\n                            placeholder-light\n                            border-radius-md\n                            select-style\n                            mb-0\n                            mt-5\n                          ",attrs:{"items":_vm.allowedActions,"item-text":"action","item-value":"action","outlined":"","single-line":"","height":"46"},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})],1):_vm._e()]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)],1)],1),_c('v-card-action',[_c('v-row',[_c('v-col',{attrs:{"offset-md":"5"}},[_c('v-btn',{staticClass:"\n                      font-weight-bold\n                      text-capitalize\n                      btn-ls btn-primary\n                      bg-success\n                      py-3\n                      px-6\n                      mb-5\n                      mt-5\n                    ",attrs:{"elevation":"0","ripple":false,"height":"46","dark":""},on:{"click":_vm.submitDispatch}},[_vm._v("Submit ")])],1)],1)],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }