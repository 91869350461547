import axios from "axios";

export default {
  async getDispatchCodeList(from, to) {
    return await axios
      .get(`dispatch/dispatch-code-list?fromDate=${from}&toDate=${to}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getDispatchDetails(dispatch_id) {
    return await axios
      .get(`independent-weigh-up-tasting/${dispatch_id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async saveData(data) {
    return await axios
      .post(`independent-weigh-up-tasting/add-tasting`, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
