<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
        z-index="2"
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-0">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="d-flex align-center">
                <div>
                  <h5 class="font-weight-bold text-h5 text-typo mb-0 mx-5 mt-2">
                    Independent Weigh Up Tasting
                  </h5>
                </div>
              </div>
              <div class="mx-5 mb-5">
                <v-form ref="frmAuctionFilter">
                  <!-- Style Fix -->
                  <v-row>
                    <v-col></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                      <label class="text-md text-typo font-weight-bolder ms-1"
                        ><span class="red--text"><strong>* </strong></span>From
                        (Start date)</label
                      >
                      <v-menu
                        ref="fromDate_menu"
                        v-model="fromDate_menu"
                        :close-on-content-click="false"
                        :return-value.sync="fromDate_menu"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.fromDate"
                            v-bind="attrs"
                            v-on="on"
                            hide-details="auto"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                              mt-5
                            "
                            @change="getDispatchList()"
                            dense
                            flat
                            filled
                            solo
                            height="46"
                            placeholder="Start Date"
                            persistent-hint
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          color="green lighten-1"
                          header-color="primary"
                          v-model="editedItem.fromDate"
                          :max="editedItem.toDate"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="fromDate_menu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              $refs.fromDate_menu.save(editedItem.fromDate),
                                getDispatchList()
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                      <label class="text-md text-typo font-weight-bolder ms-1"
                        ><span class="red--text"><strong>* </strong></span>To
                        (End date)</label
                      >
                      <v-menu
                        ref="toDate_menu"
                        v-model="toDate_menu"
                        :close-on-content-click="false"
                        :return-value.sync="toDate_menu"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.toDate"
                            v-bind="attrs"
                            v-on="on"
                            hide-details="auto"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                              mt-5
                            "
                            dense
                            flat
                            filled
                            solo
                            height="46"
                            placeholder="To Date"
                            persistent-hint
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          color="green lighten-1"
                          header-color="primary"
                          v-model="editedItem.toDate"
                          :min="editedItem.fromDate"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="toDate_menu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              $refs.toDate_menu.save(editedItem.toDate),
                                getDispatchList()
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                      <label class="text-md text-typo font-weight-bolder ms-1">
                        Dispatch code</label
                      >
                      <v-select
                        :items="dispatches"
                        item-text="dispatch_code"
                        return-object
                        hide-details="auto"
                        v-model="dispatch"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          border-radius-md
                          select-style
                          mt-5
                          mb-0
                        "
                        outlined
                        height="46"
                        single-line
                        :rules="[(v) => !!v || 'Dispatch code is required']"
                        placeholder="Select dispatch code"
                      >
                        <template v-slot:selection="{ item }">
                          <v-text
                            label
                            color="bg-default"
                            class="py-1 px-2 my-0"
                          >
                            <span class="text-caption ls-0">{{
                              item.dispatch_code
                            }}</span>
                          </v-text>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex justify-end mx-5">
                      <v-btn
                        @click="clearData"
                        elevation="0"
                        :ripple="false"
                        height="46"
                        class="
                          font-weight-bold
                          text-capitalize
                          btn-ls btn-secondary
                          bg-light
                          px-6
                          mr-2
                        "
                        >Clear</v-btn
                      >

                      <v-btn
                        @click="searchDispatch()"
                        elevation="0"
                        :ripple="false"
                        height="46"
                        dark
                        class="
                          font-weight-bold
                          text-capitalize
                          btn-ls btn-primary
                          bg-success
                          px-6
                        "
                        >Search</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </v-card>

            <v-card
              class="card-shadow border-radius-xl mt-6"
              v-show="show_dispatch_table"
              id="scroll_1"
            >
              <v-card-text class="px-0 py-0">
                <v-form ref="frmTable">
                  <v-card-text class="card-padding">
                    <v-data-table
                      v-model="selectedItems"
                      :headers="auctionHeader"
                      :items="dispatchItems"
                      item-key="tid"
                      height="600"
                      fixed-header
                      class="table"
                      :footer-props="{
                        'items-per-page-options': [100, 200, 500, -1],
                      }"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title></v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-btn
                            elevation="0"
                            :ripple="false"
                            height="46"
                            dark
                            @click="submitDispatch"
                            class="
                              font-weight-bold
                              text-capitalize
                              btn-ls btn-primary
                              bg-success
                              py-3
                              px-6
                              mb-5
                              mt-5
                            "
                            >Submit
                          </v-btn>
                        </v-toolbar>
                      </template>

                      <template v-slot:[`item.remarks`]="{ item, index }">
                        <div>
                          <v-text-field
                            v-model="item.remarks"
                            hide-details="auto"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                              mt-2
                              mb-4
                              centered-input
                            "
                            dense
                            flat
                            filled
                            @keyup="changeFocusInput"
                            :ref="`input-${index * 9 + 9}`"
                            solo
                            height="46"
                            persistent-hint
                          ></v-text-field>
                        </div>
                      </template>

                      <template v-slot:[`item.generated_item_name`]="{ item }">
                        <v-text-field
                          disabled
                          v-model="item.generated_item_name"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-2
                            mb-4
                            centered-input
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          persistent-hint
                        ></v-text-field>
                      </template>
                      <template v-slot:[`item.special_marking`]="{ item }">
                        <v-text-field
                          tabindex="-1"
                          v-model="item.special_marking"
                          @input="getItemName(item)"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-2
                            mb-4
                            centered-input
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          :value="item.special_marking"
                          persistent-hint
                          @keyup="changeFocusInput"
                          style="width: 100px"
                        ></v-text-field>
                      </template>

                      <template
                        v-slot:[`item.leaf_appearance`]="{ item, index }"
                      >
                        <v-select
                          v-model.number="item.leaf_appearance"
                          :items="leaf_appearance"
                          item-text="value"
                          item-value="value"
                          @change="getItemName(item)"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mb-0
                            mt-5
                          "
                          @keyup="changeFocusInput"
                          :ref="`input-${index * 9 + 3}`"
                          outlined
                          single-line
                          height="46"
                        >
                        </v-select>
                      </template>

                      <template v-slot:[`item.leaf_size`]="{ item, index }">
                        <v-select
                          v-model.number="item.leaf_size"
                          :items="leaf_size"
                          item-text="value"
                          item-value="value"
                          @change="getItemName(item)"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mb-0
                            mt-5
                          "
                          @keyup="changeFocusInput"
                          :ref="`input-${index * 9 + 2}`"
                          outlined
                          single-line
                          height="46"
                        >
                        </v-select>
                      </template>

                      <template v-slot:[`item.color`]="{ item, index }">
                        <v-select
                          v-model.number="item.color"
                          :items="color"
                          item-text="value"
                          item-value="value"
                          @change="getItemName(item)"
                          @keyup="changeFocusInput"
                          :ref="`input-${index * 9 + 4}`"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mb-0
                            mt-5
                          "
                          outlined
                          single-line
                          height="46"
                        >
                        </v-select>
                      </template>
                      <template v-slot:[`item.body`]="{ item, index }">
                        <v-select
                          v-model.number="item.body"
                          :items="body"
                          item-text="value"
                          item-value="value"
                          @change="getItemName(item)"
                          @keyup="changeFocusInput"
                          :ref="`input-${index * 9 + 5}`"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mb-0
                            mt-5
                          "
                          outlined
                          single-line
                          height="46"
                        >
                        </v-select>
                      </template>
                      <template v-slot:[`item.taste`]="{ item, index }">
                        <v-select
                          v-model.number="item.taste"
                          @keyup="changeFocusInput"
                          :ref="`input-${index * 9 + 6}`"
                          :items="taste"
                          item-text="value"
                          item-value="value"
                          @change="getItemName(item)"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mb-0
                            mt-5
                          "
                          outlined
                          single-line
                          height="46"
                        >
                        </v-select>
                      </template>
                      <template v-slot:[`item.comment`]="{ item }">
                        <v-text-field
                          v-model="item.comment"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-2
                            mb-4
                            centered-input
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          :value="item.comment"
                          persistent-hint
                        ></v-text-field> </template
                      ><template v-slot:[`item.below_statndard`]="{ item }">
                        <v-text-field
                          v-model="item.below_statndard"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-2
                            mb-4
                            centered-input
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          :value="item.below_statndard"
                          persistent-hint
                        ></v-text-field>
                      </template>
                      <template v-slot:[`item.status`]="{ item, index }">
                        <div
                          v-show="allowedActions.length > 0"
                          v-if="
                            item.item_code != item.generated_item_name &&
                            item.generated_item_name != null
                          "
                        >
                          <v-select
                            v-model="item.status"
                            :items="allowedActions"
                            item-text="action"
                            item-value="action"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              border-radius-md
                              select-style
                              mb-0
                              mt-5
                            "
                            :ref="`input-${index * 9 + 7}`"
                            outlined
                            single-line
                            height="46"
                          >
                          </v-select>
                        </div>
                      </template>
                    </v-data-table>
                  </v-card-text> </v-form></v-card-text
              ><v-card-action>
                <v-row>
                  <v-col offset-md="5">
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      height="46"
                      dark
                      @click="submitDispatch"
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-primary
                        bg-success
                        py-3
                        px-6
                        mb-5
                        mt-5
                      "
                      >Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-action>
            </v-card>

            <!--  -->
            <!--  -->
            <!-- Private Tasting -->
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import apiMaster from "../../../master/master-get-api";
import blend from "../../../../api/blend-api";
import api from "../api";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      currentPage: "getCurrentPage",
    }),
  },
  data() {
    return {
      leaf_appearance: [],
      leaf_size: [],
      color: [],
      body: [],
      taste: [],
      //
      overlay: false,

      auctionCenters: [],
      dispatches: [],
      dispatch: "",

      auctionCenter: "",
      seasons: [],
      season: "",
      seasonSales: [],
      seasonSale: "",
      dispatchItems: [],
      status: [{ status: "APPROVE" }, { status: "REJECT" }],
      auctionHeader: [
        {
          text: "Item",
          width: 100,
          value: "item_code",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
        {
          text: "generated name",
          width: 100,
          value: "generated_item_name",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
        {
          text: "Appearance",
          width: 100,
          value: "leaf_appearance",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
        {
          text: "size",
          width: 100,
          value: "leaf_size",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
        {
          text: "Color",
          width: 100,
          value: "color",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
        {
          text: "Body",
          width: 100,
          value: "body",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
        {
          text: "Taste",
          width: 100,
          value: "taste",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
        {
          text: "Below Standard",
          width: 100,
          value: "below_statndard",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },

        {
          text: "Remarks",
          width: 100,
          value: "remarks",
          align: "left",
          sortable: false,
        },
        {
          text: "special marking",
          width: 100,
          value: "special_marking",
          align: "left",
          sortable: false,
        },
        {
          text: "status",
          width: 100,
          value: "status",
          align: "left",
          sortable: false,
        },
        {
          text: "Batch",
          width: 100,
          value: "batch_no",
          align: "left",
          sortable: false,
        },
      ],
      allowedActions: [],

      selectedItems: [],
      show_dispatch_table: false,

      // Private

      vendors: [],
      vendor: null,
      seasons: [],
      season: null,
      offer: null,
      offers: [],
      privateTable: [],
      //
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_date: false,

      selectedItems: [],

      currentItem: 1,
      editedItem: {
        fromDate: null,
        toDate: null,
      },
      defaultItem: {
        fromDate: null,
        toDate: null,
      },
      fromDate_menu: false,
      toDate_menu: false,
    };
  },
  watch: {
    currentItem: {
      handler(value, oldValue) {
        if (value !== oldValue) {
          this.focusInput(value);
        }
      },
      immediate: true,
    },
  },
  methods: {
    getIndex(item) {
      return this.myAllocation.indexOf(item);
    },

    getInput(id) {
      const input = this.$refs[`input-${id}`];
      return input;
    },
    changeFocusInput(event) {
      const key_code = {
        left: 37,
        up: 38,
        right: 39,
        down: 40,
      };

      let nextItem = this.currentItem;

      if (event.keyCode == key_code.left) {
        nextItem -= 1;
      } else if (event.keyCode == key_code.right) {
        nextItem += 1;
      }

      if (this.getInput(nextItem)) {
        this.currentItem = nextItem;
      }
    },
    focusInput(id) {
      const input = this.getInput(id);
      if (input) {
        input.focus();
      }
    },

    async initialize() {
      console.log("currentPage.actions_allowed");

      if (this.currentPage.actions_allowed.length > 0) {
        this.currentPage.actions_allowed.forEach((el) => {
          this.allowedActions.push({ action: el });
        });
      }

      this.overlay = true;
      const date = new Date();
      this.editedItem.fromDate = this.formatDate(
        new Date(date.getFullYear(), date.getMonth(), 1)
      );
      this.editedItem.toDate = this.formatDate(
        new Date(date.getFullYear(), date.getMonth() + 1, 0)
      );
      this.getDispatchList();
      let result = await blend.getBlendfactor();
      this.body = result.body;
      this.leaf_size = result.leaf_size;
      this.color = result.color;
      this.taste = result.taste;
      this.leaf_appearance = result.leaf_appearance;
      this.auctionCenters = await apiMaster.getAuctionCenter();
      this.seasons = await apiMaster.getSeason();
      // this.seasonSales = await apiMaster.getSeasonSale();
      this.vendors = await apiMaster.getPrivateVendors();
      this.overlay = false;
    },

    async getDispatchList() {
      this.overlay = true;
      this.dispatches = await api.getDispatchCodeList(
        this.editedItem.fromDate,
        this.editedItem.toDate
      );
      this.overlay = false;
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },

    getItemName(item) {
      if (
        item.body > 0 &&
        item.color > 0 &&
        item.leaf_appearance > 0 &&
        item.leaf_size > 0 &&
        item.taste > 0
      ) {
        blend
          .getItemName(
            item.body,
            item.color,
            item.leaf_appearance,
            item.leaf_size,
            item.taste
          )
          .then((res) => {
            item.generated_item_name = res.data.name;
            // if (item.generated_item_name != item.item_code) {
            //   item.status = "APPROVE";
            // }
          });
      }
    },
    setItemName(item) {
      if (item.special_marking) {
        return `${item.item_name_1}${item.special_marking}`;
      } else {
        return item.item_name_1;
      }
    },
    scrollDown() {
      this.$nextTick(() =>
        document
          .getElementById("scroll_1")
          .scrollIntoView({ behavior: "smooth" })
      );
    },
    scrollDownPrivate() {
      this.$nextTick(() =>
        document
          .getElementById("scroll_2")
          .scrollIntoView({ behavior: "smooth" })
      );
    },

    filterValidation() {
      return this.$refs.frmAuctionFilter.validate();
    },

    async searchDispatch() {
      if (this.filterValidation()) {
        this.dispatchItems = [];
        this.selectedItems = [];
        this.show_dispatch_table = false;
        this.overlay = true;
        this.dispatchItems = await api.getDispatchDetails(this.dispatch.id);
        if (this.dispatchItems.length > 0) {
          this.selectedItems = this.dispatchItems;
          this.show_dispatch_table = true;
          this.dispatchItems.forEach((el, i) => {
            el.tid = i;
          });
          this.scrollDown();
        } else {
          this.noDataAlert("No Data Found...");
        }

        this.overlay = false;
      }
    },

    async submitDispatch() {
      this.overlay = true;
      try {
        let data = {
          dispacthMaster: this.dispatch,
          data: this.selectedItems,
        };
        await api.saveData(data);
        this.showSuccessAlert("Data Saved...");
        console.log("this.selectedItems", this.selectedItems);
      } catch (error) {
        this.showErrorAlert("Data couldn't save...");
      }

      this.overlay = false;
    },

    clear() {
      this.auctionCenter = "";
      this.seasonSale = "";
      this.vendor = null;
      this.offer = null;
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        showConfirmButton: false,
        type: "error",
        timer: 3000,
        icon: "error",
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    noDataAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,

        timer: 3000,
      });
    },
    clearData() {
      this.auctionCenter = "";

      this.show_dispatch_table = false;
      this.dispatchItems = [];
      this.selectedItems = [];
      this.dispatch = "";
      this.season = "";
      this.seasonSales = [];
      this.seasonSale = "";
      this.$refs.frmAuctionFilter.resetValidation();
    },
  },
  created() {
    this.initialize();
  },
};
</script>

<style>
.v-data-table {
  overflow-x: auto !important;
}
</style>
